/**
 * Compare two strings for equality
 *
 * @param a
 * @param b
 * @returns
 */
export const compareStrings = (a, b) => {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // names must be equal
    return 0;
};
export const updateGenericCatalogDraftItem = (draft, data, id) => {
    const updated = draft.map((item) => {
        let sortOrder = item.sort_order;
        if (typeof data.sort_order == 'string' && !isNaN(parseInt(data.sort_order))) {
            sortOrder = parseInt(data.sort_order);
        }
        else if (typeof data.sort_order == 'number') {
            sortOrder = data.sort_order;
        }
        if (item.id == id) {
            return Object.assign(Object.assign(Object.assign({}, item), data), { sort_order: sortOrder });
        }
        return item;
    });
    updated.sort((a, b) => {
        return a.sort_order - b.sort_order || compareStrings(a.name, b.name);
    });
    return updated;
};
export const deleteGenericCatalogDraftItem = (draft, id) => {
    const index = draft.findIndex((item) => item.id == id);
    const updated = draft.slice();
    if (index > -1) {
        updated.splice(index, 1);
    }
    updated.sort((a, b) => {
        return a.sort_order - b.sort_order || compareStrings(a.name, b.name);
    });
    return updated;
};
