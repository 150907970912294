/* eslint-disable sonarjs/no-identical-functions */
export var CartTagTypes;
(function (CartTagTypes) {
    CartTagTypes["CART"] = "cart";
})(CartTagTypes || (CartTagTypes = {}));
export const getCart = (build) => build.query({
    query: (url) => ({
        url,
        method: 'get',
    }),
    providesTags: [CartTagTypes.CART],
});
export const addToCart = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CartTagTypes.CART],
});
export const updateCartItem = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'put',
            data: data,
        };
    },
    invalidatesTags: [CartTagTypes.CART],
});
export const removeCartItem = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CartTagTypes.CART],
});
