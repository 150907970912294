// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Image } from 'antd';
const ImageDisplay = (props) => {
    const { image } = props;
    const { width, height, src, caption, borderRadius, alt } = image;
    return (React.createElement("div", { className: "nero-image-default" },
        React.createElement(Image, { style: { maxWidth: width || 350, maxHeight: height || 200, borderRadius }, src: src, alt: alt, preview: false, loading: "lazy" }),
        React.createElement("figcaption", null, caption)));
};
export { ImageDisplay };
