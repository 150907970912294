// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Collapse } from 'antd';
import { createMarkup } from '@nero/utils';
const { Panel } = Collapse;
const CollapseDisplay = (props) => {
    const { collapse: { header, body }, } = props;
    return (React.createElement(Collapse, { bordered: false, expandIconPosition: "end" },
        React.createElement(Panel, { key: "1", header: React.createElement("div", { dangerouslySetInnerHTML: 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
                createMarkup(header) }) },
            React.createElement("div", { className: "nero-content-block__body", dangerouslySetInnerHTML: 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
                createMarkup(body) }))));
};
export { CollapseDisplay };
