/* eslint-disable sonarjs/no-identical-functions */
export var UserTagTypes;
(function (UserTagTypes) {
    UserTagTypes["PROFILE"] = "profile";
})(UserTagTypes || (UserTagTypes = {}));
export const getProfile = (build) => build.query({
    query: (url) => {
        return {
            url,
            method: 'get',
        };
    },
    providesTags: [UserTagTypes.PROFILE],
});
export const updateProfile = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: [UserTagTypes.PROFILE],
});
