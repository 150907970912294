import { Col, Row } from 'antd';
import React from 'react';
import { ImageDisplay } from './ImageDisplay';
import { createMarkup } from '@nero/utils';
import { CollapseDisplay } from './CollapseDisplay';
import { ButtonDisplay } from './ButtonDisplay';
const GridDisplay = (props) => {
    const { grid } = props;
    return (React.createElement(Row, { justify: "space-between", className: "grid-component" }, grid.children.map((child, childIndex) => (React.createElement(Col, { key: childIndex, md: 11, sm: 24, className: "grid-component__item" },
        child && child.type == 'text' && (React.createElement("div", { className: "nero-content-block__body", dangerouslySetInnerHTML: 
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
            createMarkup(child.content) })),
        (child === null || child === void 0 ? void 0 : child.type) == 'image' && React.createElement(ImageDisplay, { image: child }),
        (child === null || child === void 0 ? void 0 : child.type) == 'collapse' && React.createElement(CollapseDisplay, { collapse: child }),
        (child === null || child === void 0 ? void 0 : child.type) == 'button' && React.createElement(ButtonDisplay, { button: child }))))));
};
export { GridDisplay };
