import { Button, Card, Col, Divider, Row, Spin } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { CALCULATE_PRICE, ORDER_NOW } from '../../lang';
import { renderBlocks } from './utils';
const Calculator = lazy(() => import('../Calculator'));
const DynamicPage = (props) => {
    const navigate = useNavigate();
    const { page, neroQuery, loginURL } = props;
    const landingPage = page.metadata.landing_page;
    return (React.createElement(React.Fragment, null,
        landingPage && page.metadata.is_home && (React.createElement("div", { className: "landing-page", style: (landingPage === null || landingPage === void 0 ? void 0 : landingPage.backgroundImageUrl)
                ? {
                    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${landingPage.backgroundImageUrl}) no-repeat center center fixed`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: '#F2F7FF',
                }
                : undefined },
            !(landingPage === null || landingPage === void 0 ? void 0 : landingPage.backgroundImageUrl) && React.createElement("div", { className: "landing-page__overlay" }),
            React.createElement("div", { className: "nero-wrapper-960 pt-30" },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 24, md: (landingPage === null || landingPage === void 0 ? void 0 : landingPage.displayCalculator) ? 14 : 24 },
                        React.createElement("h1", { className: "landing-page__title", style: { color: (landingPage === null || landingPage === void 0 ? void 0 : landingPage.backgroundImageUrl) ? '#F2F7FF' : '' } }, landingPage === null || landingPage === void 0 ? void 0 : landingPage.title),
                        React.createElement("p", { style: { color: (landingPage === null || landingPage === void 0 ? void 0 : landingPage.backgroundImageUrl) ? '#F2F7FF' : '' } }, landingPage === null || landingPage === void 0 ? void 0 : landingPage.subtitle),
                        React.createElement(Button, { className: "nero-btn-cta", size: "large", type: "primary", onClick: () => {
                                navigate(loginURL);
                            } }, (landingPage === null || landingPage === void 0 ? void 0 : landingPage.buttonLabel) || ORDER_NOW)),
                    (landingPage === null || landingPage === void 0 ? void 0 : landingPage.displayCalculator) && (React.createElement(Col, { xs: 24, md: 10 },
                        React.createElement(Card, { bordered: true, className: "landing-page__calculator" },
                            React.createElement(Divider, null,
                                React.createElement("span", { className: "landing-page__calculator-title" }, CALCULATE_PRICE)),
                            React.createElement(Suspense, { fallback: React.createElement(Spin, null) },
                                React.createElement(Calculator, { neroQuery: neroQuery, orderNowURL: loginURL }))))))))),
        renderBlocks(page.blocks)));
};
export { DynamicPage };
