// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Avatar, Card, Divider, Rate, Space } from 'antd';
import { CUSTOMER_ID } from '../../../lang';
import { UserOutlined } from '@ant-design/icons';
const ReviewDisplayItem = (props) => {
    const { rate, service, customerID, comment } = props;
    return (React.createElement(Card, { className: "nero-reviews__item", title: React.createElement(Space, { direction: "vertical", style: { width: '100%' } },
            React.createElement("span", { className: "nero-reviews__item-title" }, service),
            React.createElement(Rate, { className: "nero-reviews__item-rate", disabled: true, allowHalf: true, value: rate })) },
        React.createElement("div", { className: "nero-reviews__item-body" },
            React.createElement("p", null, comment)),
        React.createElement("div", { className: "nero-reviews__item-footer" },
            React.createElement(Divider, null),
            React.createElement(Space, null,
                React.createElement(Avatar, { icon: React.createElement(UserOutlined, null) }),
                React.createElement("span", { className: "text--meta" },
                    CUSTOMER_ID,
                    " #",
                    customerID)))));
};
export { ReviewDisplayItem };
