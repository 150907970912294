import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axios-base-query';
import { addToCart, CartTagTypes, getCart, removeCartItem, updateCartItem } from './endpoints/cart';
import { calculator, CatalogTagTypes, createCourse, createDeadline, createLevel, createPaper, createPaperFormat, deleteCourse, deleteDeadline, deleteLevel, deletePaper, deletePaperFormat, getCourses, getDeadlines, getLevels, getPaperFormats, getPapers, updateCourse, updateDeadline, updateLevel, updatePaper, updatePaperFormat, } from './endpoints/catalog';
import { getPublicSiteConfigs } from './endpoints/configs';
import { applyCoupon, CouponTagTypes, createCoupon, deleteCoupon, getCoupon, getCoupons, updateCoupon, } from './endpoints/coupon';
import { CustomerTagTypes, getCustomers } from './endpoints/customers';
import { createSelfOrder, getSingleOrder, getOrderItem, getOrderItems, getSelfOrders, getOrderStats, OrderTagTypes, updateOrderItem, getOrders, updateOrder, } from './endpoints/orders';
import { createFooterGroup, createFooterLink, createNavbarLink, createPage, createUploadedImage, deleteFooterGroup, deleteFooterLink, deleteNavbarLink, deletePage, deleteUploadedImage, getDraftPages, getFooterGroups, getFooterLinks, getNavbarLinks, getPages, getSingleNavbarLink, getUploadedImages, PageTagTypes, updateFooterGroup, updateFooterLink, updateNavbarLink, updatePage, } from './endpoints/pages';
import { getPaymentMethods } from './endpoints/payments';
import { getProfile, updateProfile, UserTagTypes } from './endpoints/user';
import { cancelSubscription, getCurrentSubscription, SubscriptionTagTypes, } from './endpoints/subscription';
import { BlogTagTypes, createCategory, createPost, createTag, deleteCategory, deletePost, deleteTag, getCategories, getPosts, getSingleCategory, getSinglePost, getTags, updateCategory, updatePost, updateTag, createImage as createBlogImage, deleteImage as deleteBlogImage, getImages as getBlogImages, } from './endpoints/blog';
export const reducerPath = 'neroApi';
/**
 * Redux toolkit query configuration using a custom base query
 */
export const neroAPIQuery = (axios) => createApi({
    reducerPath,
    baseQuery: axiosBaseQuery(axios),
    tagTypes: [
        CartTagTypes.CART,
        UserTagTypes.PROFILE,
        OrderTagTypes.SELF_ORDER,
        OrderTagTypes.ORDER_ITEM,
        OrderTagTypes.ORDER,
        PageTagTypes.PAGE,
        PageTagTypes.DRAFT_PAGE,
        PageTagTypes.UPLOADED_IMAGE,
        PageTagTypes.NAVBAR_LINK,
        PageTagTypes.FOOTER_GROUP,
        PageTagTypes.FOOTER_LINK,
        CatalogTagTypes.PAPER,
        CatalogTagTypes.LEVEL,
        CatalogTagTypes.DEADLINE,
        CatalogTagTypes.COURSE,
        CatalogTagTypes.PAPER_FORMAT,
        CustomerTagTypes.CUSTOMER,
        SubscriptionTagTypes.CURRENT_SUBSCRIPTION,
        BlogTagTypes.CATEGORY,
        BlogTagTypes.IMAGE,
        BlogTagTypes.POST,
        BlogTagTypes.TAG,
        CouponTagTypes.COUPON,
    ],
    keepUnusedDataFor: 300,
    endpoints: (build) => ({
        getSelfOrders: getSelfOrders(build),
        getSingleOrder: getSingleOrder(build),
        getPapers: getPapers(build),
        getCourses: getCourses(build),
        createCourse: createCourse(build),
        updateCourse: updateCourse(build),
        deleteCourse: deleteCourse(build),
        getPaperFormats: getPaperFormats(build),
        createPaperFormat: createPaperFormat(build),
        updatePaperFormat: updatePaperFormat(build),
        deletePaperFormat: deletePaperFormat(build),
        getCart: getCart(build),
        addToCart: addToCart(build),
        updateCartItem: updateCartItem(build),
        removeCartItem: removeCartItem(build),
        applyCoupon: applyCoupon(build),
        getProfile: getProfile(build),
        updateProfile: updateProfile(build),
        calculator: calculator(build),
        getPaymentMethods: getPaymentMethods(build),
        createOrder: createSelfOrder(build),
        createPage: createPage(build),
        getPages: getPages(build),
        getDraftPages: getDraftPages(build),
        updatePage: updatePage(build),
        deletePage: deletePage(build),
        createPaper: createPaper(build),
        updatePaper: updatePaper(build),
        deletePaper: deletePaper(build),
        getLevels: getLevels(build),
        createLevel: createLevel(build),
        updateLevel: updateLevel(build),
        deleteLevel: deleteLevel(build),
        getDeadlines: getDeadlines(build),
        createDeadline: createDeadline(build),
        updateDeadline: updateDeadline(build),
        deleteDeadline: deleteDeadline(build),
        getCustomers: getCustomers(build),
        getOrderItems: getOrderItems(build),
        getOrderItem: getOrderItem(build),
        updateOrderItem: updateOrderItem(build),
        getOrderStats: getOrderStats(build),
        getPublicSiteConfigs: getPublicSiteConfigs(build),
        getUploadedImages: getUploadedImages(build),
        createUploadedImage: createUploadedImage(build),
        deleteUploadedImage: deleteUploadedImage(build),
        getOrders: getOrders(build),
        updateOrder: updateOrder(build),
        getNavbarLinks: getNavbarLinks(build),
        getSingleNavbarLink: getSingleNavbarLink(build),
        createNavbarLink: createNavbarLink(build),
        updateNavbarLink: updateNavbarLink(build),
        deleteNavbarLink: deleteNavbarLink(build),
        getFooterLinks: getFooterLinks(build),
        createFooterLink: createFooterLink(build),
        updateFooterLink: updateFooterLink(build),
        deleteFooterLink: deleteFooterLink(build),
        getFooterGroups: getFooterGroups(build),
        createFooterGroup: createFooterGroup(build),
        updateFooterGroup: updateFooterGroup(build),
        deleteFooterGroup: deleteFooterGroup(build),
        getCurrentSubscription: getCurrentSubscription(build),
        cancelSubscription: cancelSubscription(build),
        getPosts: getPosts(build),
        getSinglePost: getSinglePost(build),
        createPost: createPost(build),
        updatePost: updatePost(build),
        deletePost: deletePost(build),
        getPostCategories: getCategories(build),
        getSinglePostCategory: getSingleCategory(build),
        createPostCategory: createCategory(build),
        updatePostCategory: updateCategory(build),
        deletePostCategory: deleteCategory(build),
        getPostTags: getTags(build),
        createPostTag: createTag(build),
        updatePostTag: updateTag(build),
        deletePostTag: deleteTag(build),
        createBlogImage: createBlogImage(build),
        deleteBlogImage: deleteBlogImage(build),
        getBlogImages: getBlogImages(build),
        getCoupons: getCoupons(build),
        createCoupon: createCoupon(build),
        getSingleCoupon: getCoupon(build),
        updateCoupon: updateCoupon(build),
        deleteCoupon: deleteCoupon(build),
    }),
});
