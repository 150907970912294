// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createMarkup } from '@nero/utils';
import { ImageDisplay } from './ImageDisplay';
import { CollapseDisplay } from './CollapseDisplay';
import { GridDisplay } from './GridDisplay';
import { ButtonDisplay } from './ButtonDisplay';
import { Col, Row } from 'antd';
import { ImageWideDisplay } from './ImageWideDisplay';
import { ReviewsDisplay } from './ReviewsDisplay';
export const renderBlocks = (blocks) => {
    return blocks.map((block, index) => (React.createElement("div", { className: "nero-content-block", key: index },
        block.type == 'text' && (React.createElement("div", { className: "nero-wrapper-960" },
            React.createElement("div", { className: "nero-content-block__body", dangerouslySetInnerHTML: 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
                createMarkup(block.content) }))),
        block.type == 'image' && block.wideWidth && React.createElement(ImageWideDisplay, { image: block }),
        block.type == 'image' && !block.wideWidth && (React.createElement("div", { className: "nero-wrapper-960" },
            React.createElement(ImageDisplay, { image: block }))),
        block.type == 'collapse' && (React.createElement("div", { className: "nero-wrapper-960" },
            React.createElement(CollapseDisplay, { collapse: block }))),
        block.type == 'grid' && (React.createElement("div", { className: "nero-wrapper-960" },
            React.createElement(GridDisplay, { grid: block }))),
        block.type == 'button' && (React.createElement(Row, { justify: "center" },
            React.createElement(Col, { sm: 24, md: 6 },
                React.createElement(ButtonDisplay, { button: block })))),
        block.type == 'review' && (React.createElement("div", { className: "nero-wrapper-960" },
            React.createElement(ReviewsDisplay, null))))));
};
