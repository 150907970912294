/* eslint-disable sonarjs/no-identical-functions */
export var BlogTagTypes;
(function (BlogTagTypes) {
    BlogTagTypes["POST"] = "BLOG_POST";
    BlogTagTypes["IMAGE"] = "BLOG_IMAGE";
    BlogTagTypes["TAG"] = "BLOG_TAG";
    BlogTagTypes["CATEGORY"] = "BLOG_CATEGORY";
})(BlogTagTypes || (BlogTagTypes = {}));
/** post queries  */
export const getPosts = (build) => build.query({
    query: (arg) => {
        const { url, params, withCredentials, headers } = arg;
        return {
            url: url,
            method: 'get',
            params,
            withCredentials,
            headers,
        };
    },
    providesTags: [BlogTagTypes.POST],
});
export const getSinglePost = (build) => build.query({
    query: (arg) => {
        const { url, slug, action } = arg;
        return {
            url: `${url}${slug}/${action || ''}`,
            method: 'get',
        };
    },
    providesTags: (result, error, arg) => [{ type: BlogTagTypes.POST, slug: arg.slug }],
});
export const createPost = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data,
        };
    },
    invalidatesTags: [BlogTagTypes.POST],
});
export const updatePost = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url: `${url}${arg.slug}/`,
            method: 'patch',
            data,
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: BlogTagTypes.POST, slug: arg.slug },
        BlogTagTypes.POST,
    ],
});
export const deletePost = (build) => build.mutation({
    query: (args) => {
        const { url, slug } = args;
        return {
            url: `${url}${slug}/`,
            method: 'delete',
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: BlogTagTypes.POST, slug: arg.slug },
        BlogTagTypes.POST,
    ],
});
/** image queries */
export const getImages = (build) => build.query({
    query: (arg) => {
        const { url, params } = arg;
        return {
            url: url,
            method: 'get',
            params,
        };
    },
    providesTags: [BlogTagTypes.IMAGE],
});
export const createImage = (build) => build.mutation({
    query: (arg) => {
        const { url, image } = arg;
        const fd = new FormData();
        fd.append('image', image);
        return {
            url,
            method: 'post',
            data: fd,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
    },
    invalidatesTags: [BlogTagTypes.IMAGE],
});
export const deleteImage = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [BlogTagTypes.IMAGE],
});
/** tag queries */
export const getTags = (build) => build.query({
    query: (arg) => {
        const { url, params, withCredentials, headers } = arg;
        return {
            url: url,
            method: 'get',
            params,
            withCredentials,
            headers,
        };
    },
    providesTags: [BlogTagTypes.TAG],
});
export const createTag = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [BlogTagTypes.TAG],
});
export const updateTag = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: [BlogTagTypes.TAG],
});
export const deleteTag = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [BlogTagTypes.TAG],
});
/** category queries */
export const getCategories = (build) => build.query({
    query: (arg) => {
        const { url, params, withCredentials, headers } = arg;
        return {
            url: url,
            method: 'get',
            params,
            withCredentials,
            headers,
        };
    },
    providesTags: [BlogTagTypes.CATEGORY],
});
export const getSingleCategory = (build) => build.query({
    query: (arg) => {
        const { url, id, action } = arg;
        return {
            url: `${url}${id}/${action || ''}`,
            method: 'get',
        };
    },
    providesTags: (result, error, arg) => [{ type: BlogTagTypes.CATEGORY, id: arg.id }],
});
export const createCategory = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [BlogTagTypes.CATEGORY],
});
export const updateCategory = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: BlogTagTypes.CATEGORY, slug: arg.id },
        BlogTagTypes.CATEGORY,
    ],
});
export const deleteCategory = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: BlogTagTypes.CATEGORY, slug: arg.id },
        BlogTagTypes.CATEGORY,
    ],
});
