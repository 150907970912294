// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Form, Input, Button, Space, Alert } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { ENDPOINT_TOKEN_GET } from '../../constants';
import { BUTTON_FORGOT_PASSWORD, BUTTON_LABEL_SUBMIT, LOADING, ERROR_EMAIL_REQUIRED, ERROR_GENERIC, ERROR_PASSWORD_REQUIRED, EMAIL, INPUT_LABEL_PASSWORD, INPUT_REMEMBER_ME, ERROR_INVALID_CREDENTIALS, ERROR_LOGIN_TOO_MANY_ATTEMPTS, } from '../../lang';
import { useForm } from 'antd/lib/form/Form';
const LoginForm = (props) => {
    var _a;
    const [isSubmitting, setSubmitting] = useState(false);
    const [failed, setFailed] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const [serverErrors, setServerErrors] = useState({});
    const [tooManyAttempts, setTooManyAttempts] = useState(false);
    const [form] = useForm();
    const { profileType, onForgotPassword, onLoginSuccess, onLoginFailed, neroApiService } = props;
    useEffect(() => {
        form.setFields([
            {
                name: 'email',
                errors: serverErrors.username || [],
            },
            {
                name: 'password',
                errors: serverErrors.password || [],
            },
        ]);
    }, [form, serverErrors]);
    return (React.createElement(Form, { form: form, className: "login-form", layout: "vertical", initialValues: { remember: true }, onValuesChange: () => {
            setFailed(false);
            setInvalidCredentials(false);
            setTooManyAttempts(false);
        }, onFinish: (values) => {
            setSubmitting(true);
            const { password, email, remember } = values;
            neroApiService
                .getAxiosInstance()
                .post(ENDPOINT_TOKEN_GET, {
                username: email,
                password,
                profile_type: profileType,
            }, {
                'axios-retry': {
                    retries: 0, // no retries incase we get a 401
                },
            })
                .then((res) => {
                setServerErrors({});
                onLoginSuccess(res.data.user, remember);
            })
                .catch((err) => {
                var _a, _b, _c;
                if (((_a = err.response) === null || _a === void 0 ? void 0 : _a.status) == 400) {
                    setServerErrors(err.response.data);
                }
                else if (((_b = err.response) === null || _b === void 0 ? void 0 : _b.status) == 401) {
                    setInvalidCredentials(true);
                }
                else if (((_c = err.response) === null || _c === void 0 ? void 0 : _c.status) == 429) {
                    setTooManyAttempts(true);
                }
                else {
                    setFailed(true);
                }
                onLoginFailed();
            })
                .finally(() => {
                setSubmitting(false);
            });
        } },
        React.createElement(Form.Item, { label: EMAIL, name: "email", rules: [{ required: true, message: ERROR_EMAIL_REQUIRED }] },
            React.createElement(Input, null)),
        React.createElement(Form.Item, { label: INPUT_LABEL_PASSWORD, name: "password", rules: [{ required: true, message: ERROR_PASSWORD_REQUIRED }] },
            React.createElement(Input.Password, null)),
        React.createElement(Form.Item, null,
            React.createElement(Form.Item, { name: "remember", valuePropName: "checked", noStyle: true },
                React.createElement(Checkbox, null, INPUT_REMEMBER_ME)),
            React.createElement(Button, { type: "link", className: "login-form__forgot", onClick: () => {
                    onForgotPassword();
                } }, BUTTON_FORGOT_PASSWORD)),
        React.createElement(Space, { direction: "vertical" },
            serverErrors.non_field_errors && (React.createElement(Alert, { type: "error", message: (_a = serverErrors.non_field_errors) === null || _a === void 0 ? void 0 : _a.join(' ') })),
            failed && React.createElement(Alert, { type: "error", message: ERROR_GENERIC }),
            invalidCredentials && React.createElement(Alert, { type: "error", message: ERROR_INVALID_CREDENTIALS }),
            tooManyAttempts && React.createElement(Alert, { type: "error", message: ERROR_LOGIN_TOO_MANY_ATTEMPTS }),
            React.createElement(Form.Item, null,
                React.createElement(Button, { type: "primary", htmlType: "submit" }, isSubmitting ? LOADING : BUTTON_LABEL_SUBMIT)))));
};
export { LoginForm };
