export var PaymentMethodCode;
(function (PaymentMethodCode) {
    PaymentMethodCode["PAYPAL"] = "PAYPAL";
    PaymentMethodCode["INSTRUCTIONS"] = "INSTRUCTIONS";
    PaymentMethodCode["TWOCHECKOUT"] = "TWOCHECKOUT";
})(PaymentMethodCode || (PaymentMethodCode = {}));
export const getPaymentMethods = (build) => build.query({
    query: (arg) => {
        const { params, url } = arg;
        return {
            url,
            method: 'get',
            params,
        };
    },
});
