import { CartTagTypes } from './cart';
/* eslint-disable sonarjs/no-identical-functions */
export var CouponTagTypes;
(function (CouponTagTypes) {
    CouponTagTypes["COUPON"] = "COUPON";
})(CouponTagTypes || (CouponTagTypes = {}));
export var CouponType;
(function (CouponType) {
    CouponType[CouponType["REGULAR"] = 1] = "REGULAR";
    CouponType[CouponType["FIRST_TIMER"] = 2] = "FIRST_TIMER";
})(CouponType || (CouponType = {}));
export const applyCoupon = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CartTagTypes.CART],
});
export const getCoupons = (build) => build.query({
    query: (arg) => {
        const { url } = arg;
        return {
            url,
            method: 'get',
        };
    },
    providesTags: [CouponTagTypes.COUPON],
});
export const createCoupon = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CouponTagTypes.COUPON],
});
export const getCoupon = (build) => build.query({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'get',
        };
    },
    providesTags: [CouponTagTypes.COUPON],
});
export const updateCoupon = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: [CouponTagTypes.COUPON],
});
export const deleteCoupon = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [CouponTagTypes.COUPON],
});
