import { CartTagTypes } from './cart';
/* eslint-disable sonarjs/no-identical-functions */
export var OrderTagTypes;
(function (OrderTagTypes) {
    OrderTagTypes["SELF_ORDER"] = "order";
    OrderTagTypes["ORDER_ITEM"] = "order_item";
    OrderTagTypes["ORDER"] = "order";
})(OrderTagTypes || (OrderTagTypes = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["PAID"] = 1] = "PAID";
    OrderStatus[OrderStatus["UNPAID"] = 2] = "UNPAID";
    OrderStatus[OrderStatus["REFUNDED"] = 3] = "REFUNDED";
    OrderStatus[OrderStatus["DECLINED"] = 4] = "DECLINED";
    OrderStatus[OrderStatus["PARTIALLY_REFUNDED"] = 5] = "PARTIALLY_REFUNDED";
})(OrderStatus || (OrderStatus = {}));
export var OrderItemStatus;
(function (OrderItemStatus) {
    OrderItemStatus[OrderItemStatus["PENDING"] = 1] = "PENDING";
    OrderItemStatus[OrderItemStatus["IN_PROGRESS"] = 2] = "IN_PROGRESS";
    OrderItemStatus[OrderItemStatus["COMPLETE"] = 3] = "COMPLETE";
    OrderItemStatus[OrderItemStatus["VOID"] = 4] = "VOID";
})(OrderItemStatus || (OrderItemStatus = {}));
export const getSelfOrders = (build) => build.query({
    query: (arg) => {
        const { params, url } = arg;
        return {
            url: url,
            method: 'get',
            params: params,
        };
    },
    providesTags: [OrderTagTypes.SELF_ORDER],
});
export const createSelfOrder = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [OrderTagTypes.SELF_ORDER, CartTagTypes.CART],
});
export const getOrderItems = (build) => build.query({
    query: (arg) => {
        const { params, url } = arg;
        return {
            url: url,
            method: 'get',
            params: params,
        };
    },
    providesTags: [OrderTagTypes.ORDER_ITEM],
});
export const getOrderItem = (build) => build.query({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'get',
        };
    },
    providesTags: (result, error, arg) => [{ type: OrderTagTypes.ORDER_ITEM, id: arg.id }],
});
export const updateOrderItem = (build) => build.mutation({
    query: (arg) => {
        const { url, id, data } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data,
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: OrderTagTypes.ORDER_ITEM, id: arg.id },
        OrderTagTypes.ORDER_ITEM,
    ],
});
export const getOrderStats = (build) => build.query({
    query: (url) => {
        return {
            url: url,
            method: 'get',
        };
    },
});
export const getOrders = (build) => build.query({
    query: (arg) => {
        const { params, url } = arg;
        return {
            url: url,
            method: 'get',
            params: params,
        };
    },
    providesTags: [OrderTagTypes.ORDER],
});
export const getSingleOrder = (build) => build.query({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'get',
        };
    },
    providesTags: (result, error, arg) => [{ type: OrderTagTypes.ORDER, id: arg.id }],
});
export const updateOrder = (build) => build.mutation({
    query: (arg) => {
        const { url, id, data } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data,
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: OrderTagTypes.ORDER, id: arg.id },
        OrderTagTypes.ORDER,
        OrderTagTypes.ORDER_ITEM,
    ],
});
