var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Custom redux toolkit base query for Nero API
 *
 * @returns
 */
export const axiosBaseQuery = (axios) => ({ url, method, data, params, headers, withCredentials }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        const result = yield axios({
            url,
            method,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            params,
            headers,
            withCredentials,
        });
        return { data: result.data };
    }
    catch (axiosError) {
        const err = axiosError;
        return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            error: { status: (_a = err.response) === null || _a === void 0 ? void 0 : _a.status, data: (_b = err.response) === null || _b === void 0 ? void 0 : _b.data },
        };
    }
});
