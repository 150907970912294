var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { neroAPIQuery } from '../service';
import { deleteGenericCatalogDraftItem, updateGenericCatalogDraftItem, compareStrings, } from './utils';
/* eslint-disable sonarjs/no-identical-functions */
export var CatalogTagTypes;
(function (CatalogTagTypes) {
    CatalogTagTypes["PAPER"] = "paper";
    CatalogTagTypes["LEVEL"] = "level";
    CatalogTagTypes["DEADLINE"] = "deadline";
    CatalogTagTypes["COURSE"] = "course";
    CatalogTagTypes["PAPER_FORMAT"] = "paper_format";
})(CatalogTagTypes || (CatalogTagTypes = {}));
export var DeadlineType;
(function (DeadlineType) {
    DeadlineType[DeadlineType["HOUR"] = 1] = "HOUR";
    DeadlineType[DeadlineType["DAY"] = 2] = "DAY";
})(DeadlineType || (DeadlineType = {}));
/** papers */
export const getPapers = (build) => build.query({
    query: (arg) => {
        const { url, params, withCredentials, headers } = arg;
        return {
            url,
            method: 'get',
            params,
            withCredentials,
            headers,
        };
    },
    providesTags: [CatalogTagTypes.PAPER],
});
export const createPaper = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CatalogTagTypes.PAPER],
});
export const updatePaper = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    // optimistic update
    onQueryStarted({ data, id, axios, getPapersUrl }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getPapers', { url: getPapersUrl }, (draft) => {
                const updated = draft.map((paper) => {
                    if (paper.id == id) {
                        let sortOrder = paper.sort_order;
                        if (typeof data.sort_order == 'string' && !isNaN(parseInt(data.sort_order))) {
                            sortOrder = parseInt(data.sort_order);
                        }
                        else if (typeof data.sort_order == 'number') {
                            sortOrder = data.sort_order;
                        }
                        return Object.assign(Object.assign(Object.assign({}, paper), data), { sort_order: sortOrder });
                    }
                    return paper;
                });
                updated.sort((a, b) => a.sort_order - b.sort_order || compareStrings(a.name, b.name));
                return updated;
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
export const deletePaper = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    // optimistic update
    onQueryStarted({ id, axios, getPapersUrl }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getPapers', { url: getPapersUrl }, (draft) => {
                const index = draft.findIndex((paper) => paper.id == id);
                const updated = draft.slice();
                if (index > -1) {
                    updated.splice(index, 1);
                }
                updated.sort((a, b) => {
                    return a.sort_order - b.sort_order || compareStrings(a.name, b.name);
                });
                return updated;
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
/** academic levels **/
export const getLevels = (build) => build.query({
    query: (url) => {
        return {
            url,
            method: 'get',
        };
    },
    providesTags: [CatalogTagTypes.LEVEL],
});
export const createLevel = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CatalogTagTypes.LEVEL],
});
export const updateLevel = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    // optimistic update
    onQueryStarted({ data, id, getLevelsUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getLevels', getLevelsUrl, (draft) => {
                return updateGenericCatalogDraftItem(draft, data, id);
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
export const deleteLevel = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    // optimistic update
    onQueryStarted({ id, getLevelsUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getLevels', getLevelsUrl, (draft) => {
                return deleteGenericCatalogDraftItem(draft, id);
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
/** deadlines **/
export const getDeadlines = (build) => build.query({
    query: (arg) => {
        const { url } = arg;
        return {
            url,
            method: 'get',
        };
    },
    providesTags: [CatalogTagTypes.DEADLINE],
});
export const createDeadline = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CatalogTagTypes.DEADLINE],
});
export const updateDeadline = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    // optimistic update
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onQueryStarted({ data, id, getDeadlinesUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getDeadlines', { url: getDeadlinesUrl }, (draft) => {
                const updated = draft.map((deadline) => {
                    if (deadline.id == id) {
                        let sortOrder = deadline.sort_order;
                        let value = deadline.value;
                        if (typeof data.sort_order == 'string' && !isNaN(parseInt(data.sort_order))) {
                            sortOrder = parseInt(data.sort_order);
                        }
                        else if (typeof data.sort_order == 'number') {
                            sortOrder = data.sort_order;
                        }
                        if (typeof data.value == 'string' && !isNaN(parseInt(data.value))) {
                            value = parseInt(data.value);
                        }
                        else if (typeof data.value == 'number') {
                            value = data.value;
                        }
                        const deadlineType = data.deadline_type || deadline.deadline_type;
                        const suffix = value > 1 ? 's' : '';
                        const fullName = deadlineType == DeadlineType.HOUR
                            ? `${value} Hour${suffix}`
                            : `${value} Day${suffix}`;
                        return Object.assign(Object.assign({}, deadline), { sort_order: sortOrder, value, deadline_type: deadlineType, full_name: fullName });
                    }
                    return deadline;
                });
                updated.sort((a, b) => {
                    return a.sort_order - b.sort_order || a.value - b.value;
                });
                return updated;
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
export const deleteDeadline = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    // optimistic update
    onQueryStarted({ id, getDeadlinesUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getDeadlines', { url: getDeadlinesUrl }, (draft) => {
                const index = draft.findIndex((deadline) => deadline.id == id);
                const updated = draft.slice();
                if (index > -1) {
                    updated.splice(index, 1);
                }
                updated.sort((a, b) => {
                    return a.sort_order - b.sort_order || a.value - b.value;
                });
                return updated;
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
/** courses */
export const getCourses = (build) => build.query({
    query: (url) => {
        return {
            url,
            method: 'get',
        };
    },
    providesTags: [CatalogTagTypes.COURSE],
});
export const createCourse = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CatalogTagTypes.COURSE],
});
export const updateCourse = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    // optimistic update
    onQueryStarted({ data, id, getCoursesUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getCourses', getCoursesUrl, (draft) => {
                return updateGenericCatalogDraftItem(draft, data, id);
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
export const deleteCourse = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    // optimistic update
    onQueryStarted({ id, getCoursesUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getCourses', getCoursesUrl, (draft) => {
                return deleteGenericCatalogDraftItem(draft, id);
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
/** paper formats */
export const getPaperFormats = (build) => build.query({
    query: (url) => {
        return {
            url,
            method: 'get',
        };
    },
    providesTags: [CatalogTagTypes.PAPER_FORMAT],
});
export const createPaperFormat = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [CatalogTagTypes.PAPER_FORMAT],
});
export const updatePaperFormat = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    // optimistic update
    onQueryStarted({ data, id, getPaperFormatsUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getPaperFormats', getPaperFormatsUrl, (draft) => {
                return updateGenericCatalogDraftItem(draft, data, id);
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
export const deletePaperFormat = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    // optimistic update
    onQueryStarted({ id, getPaperFormatsUrl, axios }, { dispatch, queryFulfilled }) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = dispatch(neroAPIQuery(axios).util.updateQueryData('getPaperFormats', getPaperFormatsUrl, (draft) => {
                return deleteGenericCatalogDraftItem(draft, id);
            }));
            try {
                yield queryFulfilled;
            }
            catch (_a) {
                // incase query was not fullfilled undo
                result.undo();
            }
        });
    },
});
/** calculator */
export const calculator = (build) => build.mutation({
    query: (arg) => {
        const { url, data, withCredentials, headers } = arg;
        return {
            url,
            method: 'post',
            data: data,
            withCredentials,
            headers,
        };
    },
});
