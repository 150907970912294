// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Button } from 'antd';
const ButtonDisplay = (props) => {
    const { button: { name, linkTo, size }, } = props;
    return (React.createElement(Button, { className: "nero-btn-cta mtb-10", size: size, type: "primary", onClick: () => {
            if (linkTo) {
                window.location.href = linkTo;
            }
        } }, name));
};
export { ButtonDisplay };
