// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { createMarkup } from '@nero/utils';
const ImageWideDisplay = (props) => {
    const { image: { src, text }, } = props;
    return (React.createElement("div", { className: "nero-image-wide", style: { backgroundImage: `url(${src})` } },
        React.createElement("div", { className: "nero-image-wide-overlay" }),
        React.createElement("div", { className: "nero-image-wide-text" },
            React.createElement("div", { className: "nero-wrapper-960" }, text && (React.createElement("div", { dangerouslySetInnerHTML: 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
                createMarkup(text) }))))));
};
export { ImageWideDisplay };
