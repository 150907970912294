var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
class HttpClient {
    constructor(baseURL, refreshTokenEndpoint, onRefreshFailed, handle403) {
        this._isRefreshing = false;
        this._onRefreshFailedCalled = false;
        this._initializeResponseInterceptor = (handle403) => {
            this.instance.interceptors.response.use(this._handleResponse, (error) => __awaiter(this, void 0, void 0, function* () {
                yield this._handleError(error, handle403);
            }));
        };
        this._handleResponse = (response) => response;
        this._handleError = (error, handle403) => {
            var _a;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) == 403 && handle403) {
                handle403(error);
            }
            else {
                return Promise.reject(error);
            }
        };
        this.instance = Axios.create({
            baseURL,
            withCredentials: true,
        });
        this._handle401Retry(baseURL, refreshTokenEndpoint, onRefreshFailed);
        this._initializeResponseInterceptor(handle403);
    }
    /**
     * Handle retrying a 401 response to try and refresh token
     *
     * @param baseURL
     * @param refreshTokenEndpoint
     */
    _handle401Retry(baseURL, refreshTokenEndpoint, onRefreshFailed) {
        axiosRetry(this.instance, {
            retries: 4,
            retryCondition(error) {
                var _a;
                return ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) == 401;
            },
            retryDelay: exponentialDelay,
            onRetry: (retryCount, error) => {
                var _a;
                // when we get the first retry for a request, we refresh token as long
                // as another request is not refreshing the token
                if (retryCount == 1 && !this._isRefreshing && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) == 401) {
                    this._handleRefreshToken(baseURL, refreshTokenEndpoint, onRefreshFailed);
                }
            },
        });
    }
    /**
     * Handle refreshing of token. Logouts out user if refresh fails
     *
     * @param baseURL
     * @param refreshTokenEndpoint
     */
    _handleRefreshToken(baseURL, refreshTokenEndpoint, onRefreshFailed) {
        // we create a new instance since we do not want to use the class instance to avoid
        // an infinite loop because of the retries
        const axios = Axios.create({
            baseURL,
            withCredentials: true,
        });
        this._isRefreshing = true;
        axios
            .post(refreshTokenEndpoint)
            .catch(() => {
            if (!this._onRefreshFailedCalled) {
                this._onRefreshFailedCalled = true;
                onRefreshFailed();
            }
        })
            .finally(() => {
            this._isRefreshing = false;
        });
    }
}
/**
 * Nero API service class. Imeplemented using the Singleton pattern to ensure
 * we  only have one instance of the class
 */
export class NeroAPIService extends HttpClient {
    constructor(baseURL, refreshTokenEndpoint, onRefreshFailed, handle403) {
        super(baseURL, refreshTokenEndpoint, onRefreshFailed, handle403);
    }
    static getInstance(baseURL, refreshTokenEndpoint, onRefreshFailed, handle403) {
        if (!this.classInstance) {
            this.classInstance = new NeroAPIService(baseURL, refreshTokenEndpoint, onRefreshFailed, handle403);
        }
        return this.classInstance;
    }
    getAxiosInstance() {
        return this.instance;
    }
}
