/* eslint-disable sonarjs/no-identical-functions */
export var CustomerTagTypes;
(function (CustomerTagTypes) {
    CustomerTagTypes["CUSTOMER"] = "customer";
})(CustomerTagTypes || (CustomerTagTypes = {}));
export const getCustomers = (build) => build.query({
    query: (arg) => {
        const { url, params } = arg;
        return {
            url,
            method: 'get',
            params,
        };
    },
    providesTags: [CustomerTagTypes.CUSTOMER],
});
