/* eslint-disable sonarjs/no-identical-functions */
export var PageTagTypes;
(function (PageTagTypes) {
    PageTagTypes["PAGE"] = "page";
    PageTagTypes["DRAFT_PAGE"] = "draft_page";
    PageTagTypes["UPLOADED_IMAGE"] = "uploaded_image";
    PageTagTypes["NAVBAR_LINK"] = "navbar_link";
    PageTagTypes["FOOTER_LINK"] = "footer_link";
    PageTagTypes["FOOTER_GROUP"] = "footer_group";
})(PageTagTypes || (PageTagTypes = {}));
/**
 * get pages without the `draft` field present
 *
 * @param build
 * @returns
 */
export const getPages = (build) => build.query({
    query: (arg) => {
        const { url, params } = arg;
        return {
            url: url,
            method: 'get',
            params,
        };
    },
    providesTags: [PageTagTypes.PAGE],
});
/**
 * get pages with the `draft` field present
 * @param build
 * @returns
 */
export const getDraftPages = (build) => build.query({
    query: (arg) => {
        const { url, params } = arg;
        return {
            url: url,
            method: 'get',
            params,
        };
    },
    providesTags: [PageTagTypes.DRAFT_PAGE],
});
export const createPage = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.DRAFT_PAGE],
});
export const updatePage = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.DRAFT_PAGE],
});
export const deletePage = (build) => build.mutation({
    query: (url) => {
        return {
            url,
            method: 'delete',
        };
    },
    invalidatesTags: [PageTagTypes.DRAFT_PAGE],
});
export const getUploadedImages = (build) => build.query({
    query: (arg) => {
        const { url, params } = arg;
        return {
            url: url,
            method: 'get',
            params,
        };
    },
    providesTags: [PageTagTypes.UPLOADED_IMAGE],
});
export const createUploadedImage = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
    },
    invalidatesTags: [PageTagTypes.UPLOADED_IMAGE],
});
export const deleteUploadedImage = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [PageTagTypes.UPLOADED_IMAGE],
});
export const getNavbarLinks = (build) => build.query({
    query: (url) => {
        return {
            url: url,
            method: 'get',
        };
    },
    providesTags: [PageTagTypes.NAVBAR_LINK],
});
export const getSingleNavbarLink = (build) => build.query({
    query: (arg) => {
        const { url, id, action } = arg;
        return {
            url: `${url}${id}/${action || ''}`,
            method: 'get',
        };
    },
    providesTags: (result, error, arg) => [{ type: PageTagTypes.NAVBAR_LINK, id: arg.id }],
});
export const createNavbarLink = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.NAVBAR_LINK],
});
export const updateNavbarLink = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: (result, error, arg) => [
        { type: PageTagTypes.NAVBAR_LINK, id: arg.id },
        PageTagTypes.NAVBAR_LINK,
    ],
});
export const deleteNavbarLink = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [PageTagTypes.NAVBAR_LINK],
});
export const getFooterLinks = (build) => build.query({
    query: (url) => {
        return {
            url: url,
            method: 'get',
        };
    },
    providesTags: [PageTagTypes.FOOTER_LINK],
});
export const createFooterLink = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.FOOTER_LINK],
});
export const updateFooterLink = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.FOOTER_LINK],
});
export const deleteFooterLink = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [PageTagTypes.FOOTER_LINK],
});
export const getFooterGroups = (build) => build.query({
    query: (url) => {
        return {
            url: url,
            method: 'get',
        };
    },
    providesTags: [PageTagTypes.FOOTER_GROUP],
});
export const createFooterGroup = (build) => build.mutation({
    query: (arg) => {
        const { url, data } = arg;
        return {
            url,
            method: 'post',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.FOOTER_GROUP],
});
export const updateFooterGroup = (build) => build.mutation({
    query: (arg) => {
        const { url, data, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'patch',
            data: data,
        };
    },
    invalidatesTags: [PageTagTypes.FOOTER_GROUP, PageTagTypes.FOOTER_LINK],
});
export const deleteFooterGroup = (build) => build.mutation({
    query: (arg) => {
        const { url, id } = arg;
        return {
            url: `${url}${id}/`,
            method: 'delete',
        };
    },
    invalidatesTags: [PageTagTypes.FOOTER_GROUP, PageTagTypes.FOOTER_LINK],
});
