export var SubscriptionTagTypes;
(function (SubscriptionTagTypes) {
    SubscriptionTagTypes["CURRENT_SUBSCRIPTION"] = "CURRENT_SUBSCRIPTION";
})(SubscriptionTagTypes || (SubscriptionTagTypes = {}));
export var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["ACTIVE"] = "ACTIVE";
    SubscriptionStatus["CANCELLED"] = "CANCELLED";
    SubscriptionStatus["SUSPENED"] = "SUSPENDED";
    SubscriptionStatus["RETIRED"] = "RETIRED";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export const getCurrentSubscription = (build) => build.query({
    query: (args) => {
        const { url, headers } = args;
        return {
            url,
            method: 'get',
            headers,
        };
    },
    providesTags: [SubscriptionTagTypes.CURRENT_SUBSCRIPTION],
});
export const cancelSubscription = (build) => build.mutation({
    query: (url) => {
        return {
            url,
            method: 'post',
            data: {},
        };
    },
    invalidatesTags: [SubscriptionTagTypes.CURRENT_SUBSCRIPTION],
});
